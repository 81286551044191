<template>
  <div>
    <el-card class="editContainer">
      <el-tabs v-model="activeName" @tab-click="handleSetTab">
        <el-tab-pane label="基础设置" name="basic">
          <el-form
            ref="form"
            class="vendorFrom"
            label-position="right"
            :rules="rules"
            :model="form"
            label-width="210px"
          >
            <el-form-item label="首页分享文案" prop="shareTitle">
              <el-input v-model.trim="form.shareTitle"></el-input>
            </el-form-item>
            <el-form-item label="首页分享图" prop="sharePicture">
              <bm-upload type="system" v-model="form.sharePicture"></bm-upload>
              <span class="assist">建议尺寸702*560</span>
            </el-form-item>

            <!-- <el-form-item label="当前平台最大的分账比例" prop="accountRatio">
          <el-input-number v-model.trim="form.accountRatio"></el-input-number>
        </el-form-item> -->

            <el-form-item label="订单超过" prop="orderOvertime">
              <el-select class="searchInput" v-model="form.orderOvertime" placeholder="请选择超时时间">
                <el-option :value="10" :label="10"> </el-option>
                <el-option :value="20" :label="20"> </el-option>
                <el-option :value="30" :label="30"> </el-option>
              </el-select>

              <span class="assist">分未付款，订单自动关闭</span>
            </el-form-item>
            <el-form-item label="退款设置">
              <el-switch :inactive-value="0" :active-value="1" v-model="form.refundState"> </el-switch>
              <span class="assist"></span>
            </el-form-item>

            <el-form-item label="扣除用户" prop="chargeProportion" v-if="form.refundState === 1">
              <el-input class="temp" oninput="value=value.replace(/[^0-9.]/g,'')" v-model="form.chargeProportion">
                <template slot="append">%</template></el-input
              >
              <bm-tip>用于未核销的核销码，用户申请退款时的扣除比例</bm-tip>
            </el-form-item>
            <el-form-item label="过期扣除比例" prop="chargeProportion" v-if="form.refundState === 1">
              <el-input class="temp" oninput="value=value.replace(/[^0-9.]/g,'')" v-model="form.expiredProportion">
                <template slot="append">%</template></el-input
              >
              <bm-tip>用于过期的核销码，用户申请退款时的扣除比例</bm-tip>
            </el-form-item>
            <el-form-item v-if="form.refundState === 1">
              <span class="assist">申请退款-原路退回方式，将扣除此比例费用</span>
            </el-form-item>

            <!-- <el-form-item label="微信手续费">
        <el-radio v-model="form.wxServiceCharge" :label="0">商户承担(默认)</el-radio>
        <el-radio v-model="form.wxServiceCharge" :label="1">平台承担</el-radio>
      </el-form-item> -->

            <el-form-item>
              <el-button type="primary" @click="settingMall">保存</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="商户号设置" name="busniess">
          <el-table :data="tableData" style="width: 100%">
            <el-table-column prop="mchName" label="商户号名称" align="center"> </el-table-column>
            <el-table-column prop="defaultMch" label="是否默认商户" align="center">
              <template slot-scope="{ row }">
                <el-switch
                  :value="row.defaultMch"
                  @change="handleSetDefault($event, row.id)"
                  :disabled="row.defaultMch"
                >
                </el-switch>
              </template>
            </el-table-column>
            <el-table-column prop="smallChangeLimit" label="收款人提现上限(元)" align="center"> </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="{ row }">
                <el-button type="text" @click="handleEdit(row)">编辑</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
      <el-dialog :close-on-click-modal="false" title="提示" :visible.sync="showEdit" width="30%">
        <el-form ref="editForm" class="vendorFrom" :rules="editRules" :model="editForm" label-width="210px">
          <el-form-item label="商户号名称" prop="mchName">
            <span>{{ editForm.mchName }}</span>
          </el-form-item>
          <el-form-item label="收款人每天提现上限微信零钱" prop="smallChangeLimit">
            <el-input-number v-model="editForm.smallChangeLimit" :min="0" style="width: 140px" />
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="showEdit = false">取 消</el-button>
          <el-button type="primary" @click="confirmBlock">确 定</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sharePicture: [],
      form: {
        accountRatio: 0,
        orderOvertime: '',
        sharePicture: '',
        shareTitle: '',
        chargeProportion: '',
        refundState: 1,
        id: 0,
        wxServiceCharge: 0,
        expiredProportion: 0
      },
      activeName: 'basic',
      rules: {},
      tableData: [],
      showEdit: false,
      editRules: {
        smallChangeLimit: [
          {
            required: true,
            message: '请输入提现上限微信零钱',
            trigger: 'blur'
          }
        ]
      },
      editForm: {
        mchName: '',
        smallChangeLimit: 0
      },
      currentId: 0
    }
  },
  methods: {
    handleSetTab(tab) {
      let { name } = tab
      this.activeName = name
    },
    handleSetDefault(val, id) {
      if (!val) return
      this.$confirm('确定设置为默认值吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.base
          .base('mchSettingSwitch', id)
          .then(res => {
            this.$message.success('修改成功')
            this.loadData()
          })
          .catch(err => {
            this.$message.error(err.msg || '操作失败')
          })
      })
    },
    handleEdit(row) {
      this.showEdit = true
      this.editForm = { ...row }
      this.currentId = row.id
    },
    confirmBlock() {
      this.$refs.editForm.validate(valid => {
        if (valid) {
          this.$api.base
            .base('mchSettingEdit', this.currentId, {
              smallChangeLimit: this.editForm.smallChangeLimit
            })
            .then(res => {
              this.$message.success('修改成功')
              this.loadData()
              this.showEdit = false
            })
            .catch(err => {
              this.$message.error(err.msg || '操作失败')
            })
        }
      })
    },
    getRules() {
      this.rules = this.$initRules([
        {
          label: '首页分享文案',
          value: 'shareTitle',
          type: 'input',
          required: true
        },
        // {
        //   label: '当前平台最大的分账比例',
        //   value: 'accountRatio',
        //   type: 'input',
        //   required: true
        // },
        {
          label: '订单限时',
          value: 'orderOvertime',
          type: 'input',
          required: true
        },
        {
          label: '订单限时',
          value: 'orderOvertime',
          type: 'input',
          required: true
        },
        {
          label: '扣除用户数',
          value: 'chargeProportion',
          type: 'input',
          required: true
        }
      ])
    },

    // 修改商城设置的信息
    settingMall() {
      if (this.form.refundState === 0) {
        this.form.chargeProportion = 0
      }
      this.$refs['form'].validate((valid, validObj) => {
        if (!valid) {
          this.$message.error(validObj[Object.keys(validObj)[0]][0].message)
          return true
        } else {
          let params = {
            accountRatio: this.form.accountRatio,
            orderOvertime: this.form.orderOvertime,
            sharePicture: this.form.sharePicture,
            shareTitle: this.form.shareTitle,
            chargeProportion: this.form.chargeProportion,
            refundState: this.form.refundState,
            id: this.form.id,
            wxServiceCharge: this.form.wxServiceCharge,
            expiredProportion: this.form.expiredProportion
          }
          if (this.form.id === 0) {
            const loading = this.$loading({
              lock: true,
              text: '正在提交，请稍候',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)'
            })
            this.$api.base
              .base('postMallSetting', params)
              .then(res => {
                if (res.code === 5000) {
                  this.$message.warning(res.msg)
                } else {
                  this.$message.success('添加成功')
                  this.getMallsSetting()
                }
              })
              .catch(err => {
                this.$message.error(err.msg || '操作失败')
              })
              .finally(() => {
                loading.close()
              })
          } else {
            const loading = this.$loading({
              lock: true,
              text: '正在提交，请稍候',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)'
            })
            this.$api.base
              .base('putMallSetting', params)
              .then(res => {
                if (res.code === 5000) {
                  this.$message.warning(res.msg)
                } else {
                  this.$message.success('修改成功')
                  this.getMallsSetting()
                }
              })
              .catch(err => {
                this.$message.error(err.msg || '操作失败')
              })
              .finally(() => {
                loading.close()
              })
          }
        }
      })
    },
    // 获取原先商城设置的信息
    getMallsSetting() {
      console.log('123')
      this.$api.base
        .base('getMallSetting')
        .then(res => {
          if (res) {
            this.form = res
          }
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
    },
    loadData() {
      this.$api.base
        .base('mchSettingList')
        .then(res => {
          if (res) {
            this.tableData = res
          }
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
    }
  },
  created() {
    this.getRules()
    this.getMallsSetting()
    this.loadData()
  }
}
</script>

<style lang="less" scoped>
.editContainer {
  color: @color-primary;
  margin: @container-margin;
  min-height: calc(100% - 40px);
  .vendorFrom {
    margin-left: 20px;
    .el-input-number,
    .el-select,
    .el-input {
      width: 250px;
    }

    .assist {
      .assistText;
    }
  }
}
</style>
